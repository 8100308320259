import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { serverURL } from '../constants';
import { Card, Spinner } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faSolidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faRegularHeart } from '@fortawesome/free-regular-svg-icons';

const CommunityCourses = ({ userId }) => {
    const [courses, setCourses] = useState([]);
    const [processing, setProcessing] = useState(true);
    const [likedCourses, setLikedCourses] = useState({});
    const [userNames, setUserNames] = useState({}); // For storing user names

    useEffect(() => {
        let isMounted = true;
    
        const fetchUserCourses = async () => {
            const postURL = `${serverURL}/api/courses?public=true`;
            const likesURL = `${serverURL}/api/likescheck`;
    
            try {
                // Fetch public courses
                const coursesResponse = await axios.get(postURL);
    
                // Fetch user's liked courses (POST request)
                const likedResponse = await axios.post(likesURL, { userId });
    
                if (isMounted) {
                    setCourses(coursesResponse.data); // Set the courses
    
                    // Process liked courses
                    const likedCourseIds = likedResponse.data.likedCourses;
                    const updatedLikedCourses = {};
                    likedCourseIds.forEach(courseId => {
                        updatedLikedCourses[courseId] = true;
                    });
                    setLikedCourses(updatedLikedCourses);
                    setProcessing(false);
    
                    // Fetch the names of users associated with each course
                    const userIds = coursesResponse.data.map(course => course.user); // Get user IDs
                    const uniqueUserIds = [...new Set(userIds)]; // Remove duplicates
    
                    // Fetch user details for each unique user ID
                    const userDetailsPromises = uniqueUserIds.map(userId =>
                        axios.get(`${serverURL}/api/users/${userId}`)
                    );
                    const userDetailsResponses = await Promise.all(userDetailsPromises);
    
                    // Create a userId-to-name mapping
                    const userNamesMap = {};
                    userDetailsResponses.forEach((response, index) => {
                        const userId = uniqueUserIds[index];
                        userNamesMap[userId] = response.data.name; // Access 'name' field correctly
                    });
    
                    setUserNames(userNamesMap); // Save the userId-name map in state
                }
            } catch (error) {
                console.error("Error fetching courses or likes:", error);
                setProcessing(false); // Stop the loading spinner even if there's an error
            }
        };
    
        fetchUserCourses();
    
        return () => {
            isMounted = false;
        };
    }, [userId]);    

    const navigate = useNavigate();

    const handleCourse = async (content, mainTopic, selectedModel, type, user, courseId, completed, end) => {
        const jsonData = JSON.parse(content);
        sessionStorage.setItem('courseId', courseId);
        sessionStorage.setItem('first', completed);
        sessionStorage.setItem('jsonData', JSON.stringify(jsonData));
        
        let ending = '';
        if (completed) {
            ending = end;
        }
    
        try {
            // Update the recent course
            await updateRecentCourse(courseId, userId); // Call the function to update recent course
    
            // Update course views
            const postURL = serverURL + '/api/views';
            await axios.post(postURL, { courseId: courseId });
        } catch (error) {
            console.error("Error updating course:", error);
            // You could also show a user notification or handle the error more gracefully
        }
    
        // Navigate to the course page with the necessary data
        navigate('/course', { state: { 
            jsonData: jsonData, 
            mainTopic: mainTopic.toUpperCase(), 
            selectedModel: selectedModel, 
            type: type.toLowerCase(), 
            userId: userId,
            creatorId: user,
            courseId: courseId, 
            end: ending 
        }});
    };    

    const updateRecentCourse = async (courseId, userId) => {
        const dataToSend = {
            courseId: courseId,
            userId: userId,
        };
    
        const postURL = serverURL + `/api/recent`; // Construct the API URL
        try {
            // Send a POST request to update the recent course
            await axios.post(postURL, dataToSend);
        } catch (error) {
            console.error("Error updating recent course:", error);
            // Optionally, you can add further error handling here, like showing a notification to the user
        }
    };    

    const handleCertificate = (mainTopic, end) => {
        const ending = new Date(end).toLocaleDateString();
        navigate('/certificate', { state: { courseTitle: mainTopic, end: ending } });
    };

    // Toggle like status
    const toggleLike = async (courseId) => {
        setLikedCourses(prevState => ({
            ...prevState,
            [courseId]: !prevState[courseId]
        }));

        try {
            const postURL = `${serverURL}/api/likes`;
            await axios.post(postURL, { userId: userId, courseId: courseId });
        } catch (error) {
            console.error("Error updating like status:", error);
        }
    };

    const style = {
        root: {
            base: "flex flex-col rounded-none border border-black bg-white shadow-none dark:border-white dark:bg-black mx-2 my-4",
            children: "flex h-full flex-col justify-center gap-3 p-5",
            horizontal: {
                off: "flex-col",
                on: "flex-col md:flex-row"
            },
            href: "hover:bg-white dark:hover:bg-black"
        },
        img: {
            base: "",
            horizontal: {
                off: "rounded-none",
                on: "h-96 w-full rounded-none object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
            }
        }
    };

    return (
        <div className='my-4'>
            {processing ? (
                <div className="text-center h-screen w-screen flex items-center justify-center">
                    <Spinner size="xl" className='fill-black dark:fill-white' />
                </div>
            ) : (
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
                    {courses.map((course) => (
                        <div key={course._id} className='w-full relative'>
                            <Card imgSrc={course.photo} theme={style}>
                                <h5 style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} className='text-xl font-black tracking-tight text-black dark:text-white'>
                                    {course.mainTopic.toUpperCase()}
                                </h5>
                                <p className='font-normal text-sm capitalize text-black dark:text-white'>
                                    {course.type}
                                </p>
                                <p className='font-normal text-sm text-black dark:text-white'>
                                    {new Date(course.date).toLocaleDateString()}
                                </p>

                                {/* New "by so and so" section */}
                                <p className='font-normal text-sm text-gray-700 dark:text-gray-300'>
                                    by {userNames[course.user] || 'Unknown'}
                                </p>

                                {/* Tally for views and likes with icons */}
                                <div className='flex-row flex items-center space-x-4 mt-2'>
                                    <p className='text-sm font-normal text-gray-700 dark:text-gray-300 flex items-center'>
                                        <FontAwesomeIcon icon={faEye} className='mr-1' /> {course.views} views
                                    </p>
                                    <p className='text-sm font-normal text-gray-700 dark:text-gray-300 flex items-center'>
                                        <FontAwesomeIcon icon={faHeart} className='mr-1' /> {course.likes} likes
                                    </p>
                                </div>

                                <div className='flex-row flex space-x-4'>
                                    <button 
                                        onClick={() => handleCourse(course.content, course.mainTopic, course.selectedModel, course.type, course.user, course._id, course.completed, course.end)} 
                                        className='bg-black text-white px-5 py-2 font-medium dark:bg-white dark:text-black'>
                                        View
                                    </button>
                                </div>
                            </Card>
                            <div className="absolute top-7 right-6">
                                <FontAwesomeIcon 
                                    icon={likedCourses[course._id] ? faSolidHeart : faRegularHeart} 
                                    onClick={() => toggleLike(course._id)} 
                                    className={`text-4xl cursor-pointer ${likedCourses[course._id] ? 'text-red-500' : 'text-gray-400'}`} 
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CommunityCourses;
