import React from 'react';
import { Card } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { serverURL } from '../constants';
import axios from 'axios';


const PricingPlan = (props) => {

    const navigate = useNavigate();
    async function redirectPayment() {
        if (!props.header) {
            // If the header indicates the user is not logged in, redirect to /signup
            navigate('/signup');
            return;
        }

        if (sessionStorage.getItem('auth') === null || sessionStorage.getItem('type') === 'free') {
            const userId = sessionStorage.getItem('uid');
            const dataToSend = {
                plan: props.data.api,
                userId: userId,
            };
            const postURL = serverURL + '/api/stripecheckout';
            try {
                const response = await axios.post(postURL, dataToSend);
                if (response.data.success && response.data.sessionUrl) {
                    window.location.href = response.data.sessionUrl;  // Redirect to the Stripe checkout session
                }
            } catch (error) {
                console.error('Error during the request:', error);
            }
        }
    }

    return (
        <Card className='max-w-sm shadow-none border border-black rounded-none mt-3 px-14 py-5 dark:bg-black dark:border-white'>
            <h5 className="mb-4 text-3xl max-md:text-2xl font-black text-black dark:text-white text-center">{props.data.type}</h5>
            <div className="flex items-baseline text-gray-900 dark:text-white text-center justify-center">
                <span className="text-3xl max-md:text-2xl font-semibold ">$</span>
                <span className="text-5xl max-md:text-3xl font-extrabold tracking-tight">{props.data.cost}</span>
                <span className="ml-1 text-xl font-normal text-black dark:text-white">{props.data.time}</span>
            </div>
            <span className="border border-yellow-500 p-2 text-center">
                <span className="text-4xl max-md:text-3xl font-extrabold text-black dark:text-white text-center">{props.data.one}</span>
            </span>
            <button
                onClick={redirectPayment}
                className="inline-flex w-full justify-center dark:bg-white dark:text-black bg-black px-5 py-2.5 text-center text-sm font-bold text-white "
            >{sessionStorage.getItem('auth') === null || sessionStorage.getItem('type') === 'free' ? "Get Started" : "Modify Plan"}
            </button>
        </Card>
    );
};

export default PricingPlan;
