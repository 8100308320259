import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Ensure axios is imported
import Header from '../components/header';
import Footers from '../components/footers';
import { FaHeart, FaEye } from 'react-icons/fa';
import BrainIcon from '../res/img/brainicon.png'; // Import the brain icon
import BrainPower from '../res/img/brainpower.png'; // Import the brain background image
import UserIcon from '../res/img/usericon.png'; // Import the brain icon
import { serverURL } from '../constants';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const [recentCourse, setRecentCourse] = useState(null);
    const [processing, setProcessing] = useState(true);
    const [userName, setUserName] = useState(null); // State for user name
    const [profilePicture, setProfilePicture] = useState(null);
    const [type, setType] = useState(null);
    const [tokens, setTokens] = useState(0);
    const [recentUserName, setRecentUserName] = useState(null); // State for recent user name
    const [recentProfilePicture, setRecentProfilePicture] = useState(null);
    const [topCourses, setTopCourses] = useState([]); // State for top trending posts
    const userId = sessionStorage.getItem('uid');
    const [baseValue, setBaseValue] = useState(172);

    useEffect(() => {
        const updateBaseValue = () => {
            const width = window.innerWidth;
            if (width < 1280) {
                setBaseValue(114); // For screens below xl (e.g., sm, md, lg)
            } else if (width < 1536) {
                setBaseValue(158); // For xl screens (1280px to 1535px)
            } else {
                setBaseValue(172); // For 2xl screens (1536px and up)
            }
        };
        const fetchHomeInfo = async () => {
            if (!userId) {
                setProcessing(false);
                return;
            }

            const postURL = `${serverURL}/api/recent?userId=${userId}`;
            try {
                // Fetch the recent course details directly
                const response = await axios.get(postURL);
                if (response.data.success !== false) {
                    setRecentCourse(response.data.course); // Set the recent course details directly
                    setUserName(response.data.mName); // Assuming userResponse contains a 'name' field
                    setProfilePicture(response.data.profilePicture);
                    setType(response.data.type);
                    setTokens(response.data.tokens);

                    // Fetch the user details for the author name of the recent course
                    const userResponse = await axios.get(`${serverURL}/api/users/${response.data.course.user}`);
                    setRecentUserName(userResponse.data.name); // Set the recent user name
                    setRecentProfilePicture(userResponse.data.profilePicture);

                } else {
                    setRecentCourse(null); // No recent course found
                    setUserName(response.data.mName); // Assuming userResponse contains a 'name' field
                    setProfilePicture(response.data.profilePicture);
                    setType(response.data.type);
                    setTokens(response.data.tokens);
                }
            } catch (error) {
                console.error("Error fetching recent course:", error);
                setRecentCourse(null); // Explicitly set to null on error
            }

            // Fetch all public courses independently of the recent course fetch
            try {
                const coursesResponse = await axios.get(`${serverURL}/api/courses?public=true`);

                // Check if courses exist in the response
                if (coursesResponse.data && Array.isArray(coursesResponse.data)) {
                    const courses = coursesResponse.data; // Assuming the courses are returned in this format

                    // Create an array to hold promises for fetching usernames
                    const coursesWithUsernames = await Promise.all(courses.map(async (course) => {
                        // Fetch user info for each course
                        const userResponse = await axios.get(`${serverURL}/api/users/${course.user}`);
                        return {
                            ...course,
                            name: userResponse.data.name,
                            profilePicture: userResponse.data.profilePicture
                        };
                    }));

                    // Calculate scores for each course and sort them
                    const rankedCourses = coursesWithUsernames.map(course => {
                        const score = course.dailyLikes * 2 + course.dailyViews; // Calculate score
                        return { ...course, score }; // Add score to the course object
                    }).sort((a, b) => b.score - a.score); // Sort descending by score

                    // Get the top 3 courses
                    const topCourses = rankedCourses.slice(0, 3).map((course, index) => ({
                        ...course,
                        rank: index + 1 // Assign ranks 1, 2, 3
                    }));

                    setTopCourses(topCourses); // Set the topCourses state
                } else {
                    console.error("No courses found in the response or the response is not an array.");
                    setTopCourses([]); // Set to an empty array if no courses are found
                }
            } catch (error) {
                console.error("Error fetching courses:", error);
                setTopCourses([]); // Explicitly set to empty on error
            }

            setProcessing(false);
        };

        fetchHomeInfo();
        // Set the initial baseValue.
        updateBaseValue();
        window.addEventListener('resize', updateBaseValue);
        return () => window.removeEventListener('resize', updateBaseValue);
    }, [userId]);

    const navigate = useNavigate();

    const handleCourse = async (content, mainTopic, isPublic, selectedModel, type, user, courseId, completed, end) => {
        const jsonData = JSON.parse(content);
        sessionStorage.setItem('courseId', courseId);
        sessionStorage.setItem('first', completed);
        sessionStorage.setItem('jsonData', JSON.stringify(jsonData));
        let ending = '';
        if (completed) {
            ending = end;
        }

        try {
            // Update the recent course
            await updateRecentCourse(courseId, userId); // Call the function to update recent course
        } catch (error) {
            console.error("Failed to update recent course:", error);
        }

        // Navigate to the course page with the necessary data
        navigate('/course', { state: { jsonData: jsonData, mainTopic: mainTopic.toUpperCase(), isPublic: isPublic, selectedModel: selectedModel, type: type.toLowerCase(), userId: user, creatorId: userId, courseId: courseId, end: ending } });
    };

    const updateRecentCourse = async (courseId, userId) => {
        const dataToSend = {
            courseId: courseId,
            userId: userId,
        };

        const postURL = serverURL + `/api/recent`; // Construct the API URL
        try {
            // Send a POST request to update the recent course
            await axios.post(postURL, dataToSend);
        } catch (error) {
            console.error("Error updating recent course:", error);
        }
    };

    const formatTimeSince = (date) => {
        const now = new Date();
        const diffInMilliseconds = now - new Date(date);
        const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
        const diffInMonths = Math.floor(diffInDays / 30); // Approximation
        const diffInYears = Math.floor(diffInMonths / 12); // Approximation

        if (diffInMinutes < 60) {
            return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
        } else if (diffInHours < 24) {
            return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
        } else if (diffInDays < 30) {
            return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
        } else if (diffInMonths < 12) {
            return `${diffInMonths} month${diffInMonths !== 1 ? 's' : ''} ago`;
        } else {
            return `${diffInYears} year${diffInYears !== 1 ? 's' : ''} ago`;
        }
    };

    const style = {
        root: {
            base: "col-span-1 sm:row-start-1 rounded-none border border-black bg-white shadow-none dark:border-white dark:bg-black mx-2 my-2 p-2 sm:p-4 pt-0 sm:pt-1",
            children: "flex h-full flex-col justify-center gap-3 p-3",
            horizontal: {
                off: "flex-col",
                on: "flex-col md:flex-row"
            },
            href: "hover:bg-white dark:hover:bg-black"
        },
        img: {
            base: "",
            horizontal: {
                off: "rounded-none",
                on: "h-36 w-full rounded-none object-cover md:h-auto md:w-32 md:rounded-none md:rounded-l-lg"
            }
        }
    };

    const displayName = userName || 'Unknown Author'; // Default if userName is not fetched
    const displayRecentName = recentUserName || 'Unknown Author'; // Default if recentUserName is not fetched
    const displayType = type
    const displayTokens = tokens
    const displayRecentProfilePicture = recentProfilePicture;

    return (
        <div className="min-h-screen flex flex-col dark:bg-black">
            <Header isHome={true} className="sticky top-0 z-50" />

            {/* Main Content */}
            <div className="dark:bg-black p-2 sm:p-4 pb-0 flex flex-col items-start overflow-hidden flex-1">

                {/* Welcome and Profile Section */}
                <div className="flex justify-between items-center sm:mb-4 w-full">
                    <h1 className="text-xl sm:text-2xl font-black tracking-tight text-white">Welcome back, {displayName}</h1>
                </div>

                {/* 3-Column Main Section */}
                <div className="grid grid-cols-2 grid-rows-2 sm:grid-cols-3 sm:grid-rows-1 gap-0 sm:gap-4 h-full w-full">

                    {/* Recent Section */}
                    <div className={`${style.root.base} row-span-1 sm:col-start-1"`}>
                        <h2 className="text-2xl sm:text-4xl font-black tracking-tight text-white mb-1 sm:mt-1">Recent</h2>
                        <hr className="mb-2 sm:mb-4 border-t border-gray-500" />

                        {processing ? (
                            <p className="text-white">No trending courses found.</p>
                        ) : recentCourse ? (
                            <a
                                key={recentCourse._id} // Use the unique ID as the key
                                href="#"
                                className="flex flex-col rounded-md overflow-hidden shadow-lg transition-shadow duration-200 group"
                                onClick={() => handleCourse(recentCourse.content, recentCourse.mainTopic, recentCourse.public, recentCourse.selectedModel, recentCourse.type, recentCourse.user, recentCourse._id, recentCourse.completed, recentCourse.end)} // Trigger course navigation
                            >
                                <img
                                    src={recentCourse.photo || 'https://picsum.photos/800/400?random=1'} // Use course photo or a placeholder
                                    alt={`Course ${recentCourse.title || 'Title not available'}`}
                                    className="w-full h-full sm:h-80 object-cover"
                                    loading="lazy"
                                />
                                <div className="p-1 sm:p-6 sm:pb-3 bg-transparent text-white group-hover:bg-white group-hover:text-black transition duration-300">
                                    <h5 className='text-sm sm:text-xl font-black tracking-tight'>
                                        {recentCourse.mainTopic.toUpperCase()}
                                    </h5>
                                    <div className="flex sm:hidden items-center space-x-2 mb-1">
                                        <img
                                            src={displayRecentProfilePicture || UserIcon} // Assuming authorId is available for unique avatar
                                            className="h-5 w-5 sm:h-6 sm:w-6 rounded-full object-cover"
                                        />
                                        <span className="text-xs text-gray-400 group-hover:text-gray-700">{displayRecentName}</span>
                                    </div>
                                    <p className="hidden mb-4">{recentCourse.description || 'Description not yet ready'}</p> {/* Placeholder for description */}
                                    <div className="hidden sm:flex items-center mt-16">
                                        <img
                                            src={displayRecentProfilePicture || UserIcon} // Placeholder for user avatar
                                            className="h-12 w-12 rounded-full object-cover mr-4"
                                        />
                                        <div>
                                            <p className="font-medium">{displayRecentName}</p>
                                            <p className="text-sm">Instructor</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        ) : (
                            <p className="text-white">No recent courses found.</p>
                        )}
                    </div>

                    {/* Trending Section */}
                    <div className={`${style.root.base} row-span-2 sm:row-span-1 sm:col-start-2`}>
                        <h2 className="text-2xl sm:text-4xl font-black tracking-tight text-white mb-1 sm:mt-1">Trending</h2>
                        <hr className="mb-2 border-t border-gray-500" />
                        <div className="">
                            {topCourses.length > 0 ? (
                                topCourses.map((course) => (
                                    <a
                                        href="#"
                                        key={course._id} // Unique key for each post
                                        className="relative flex flex-col sm:flex-row sm:space-x-4 transition-shadow duration-200 group"
                                        onClick={() => handleCourse(course.content, course.mainTopic, course.public, course.selectedModel, course.type, course.user, course._id, course.completed, course.end)}
                                    >
                                        <div className="flex flex-col sm:flex-row sm:space-x-4 p-1 sm:p-4 bg-transparent group-hover:bg-white group-hover:shadow-lg transition duration-300 rounded-md flex-grow relative">
                                            <div className="flex-shrink-0 relative">
                                                {/* Wrap the image and icons */}
                                                <div className="relative sm:group-hover:scale-105 transition-transform duration-300">
                                                    <img
                                                        className="w-full h-full sm:h-36 sm:w-44 object-cover rounded-md transition-transform duration-300"
                                                        src={course.photo || `https://picsum.photos/600/400?random=${course._id}`} // Fallback to random image if no photo
                                                        alt={`Trending Article ${course.mainTopic || 'Title not available'}`}
                                                        loading="lazy"
                                                    />
                                                    {/* Icons Container */}
                                                    <div className="absolute top-0 left-0 flex items-center space-x-1 bg-black bg-opacity-60 rounded-br-md rounded-tl-md transition duration-300 group-hover:bg-opacity-70 p-1">
                                                        <span className="flex items-center text-sm text-white">
                                                            <FaHeart className="mr-1" />
                                                            {course.likes}
                                                        </span>
                                                        <span className="flex items-center text-sm text-white">
                                                            <FaEye className="mr-1" />
                                                            {course.views}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-2 sm:mt-0 flex-1 flex flex-col">
                                                <div className="text-white group-hover:text-black transition duration-300 flex-1">
                                                    <h3 className="font-black text-sm xl:text-base 2xl:text-lg tracking-tight sm:mb-1">{course.mainTopic.toUpperCase() || 'Title not available'}</h3>
                                                    <div className=" flex flex-col space-x-2 2xl:flex-row 2xl:items-center">
                                                        <div className="flex items-center space-x-2 mb-1">
                                                            <img
                                                                src={course.profilePicture || UserIcon}
                                                                className="h-5 w-5 sm:h-6 sm:w-6 rounded-full object-cover"
                                                            />
                                                            <span className="text-xs text-gray-400 group-hover:text-gray-700">{course.name || 'Unknown'}</span>
                                                        </div>
                                                        <div className="hidden sm:flex items-center space-x-2 mb-1">
                                                            <span className="text-xs text-gray-500">•</span>
                                                            <span className="text-xs text-gray-400 group-hover:text-gray-700">{formatTimeSince(course.date)}</span>
                                                        </div>
                                                    </div>
                                                    <p className="hidden text-sm mb-2 text-gray-300 group-hover:text-gray-700">
                                                        {course.description || 'Description not yet ready'} {/* Placeholder for description */}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="border-b border-gray-800 sm:hidden" />
                                    </a>
                                ))
                            ) : (
                                <p className="text-white">Loading recent courses...</p>
                            )}
                        </div>
                    </div>


                    {/* User Info Section */}
                    <div className={`${style.root.base} row-span-1 sm:col-start-3`}>
                        <h2 className="text-2xl sm:text-4xl font-black tracking-tight text-white mb-1 sm:mt-1">User Info</h2>
                        <hr className="mb-2 sm:mb-4 border-t border-gray-500" />

                        <div className="flex flex-col mt-4 space-y-6">
                            {/* Brain Icon and Level System */}
                            <div className="flex flex-col xl:flex-row space-y-3 sm:space-y-0 flex-1 items-center justify-around ">
                                {/* Brain Icon */}
                                <div className="relative flex flex-col items-center w-32 h-32 xl:w-44 xl:h-44 2xl:w-48 2xl:h-48">
                                    <svg>
                                        <defs>
                                            <clipPath id="clip-shape">
                                                <rect width="100%" className="h-[127px] xl:h-[175px] 2xl:h-[192px]" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    {(() => {
                                        // Determine the total tokens provided by the subscription pack based on the type.
                                        let packTokens = 0;
                                        if (displayType === "price_1QJ4E1CmPWQ8eDjjx0dhziNU") {
                                            packTokens = 5000000;
                                        } else if (displayType === "price_1QJ4F2CmPWQ8eDjjSgAI1ZLr") {
                                            packTokens = 10000000;
                                        }

                                        // Calculate the percentage of tokens remaining.
                                        // We clamp the value to 100% in case tokens exceed the pack's amount.
                                        const percentage = packTokens ? Math.min((displayTokens / packTokens) * 100, 100) : 0;
                                        const topValue = baseValue - (percentage * baseValue) / 100;

                                        return (
                                            <div
                                                style={{
                                                    clipPath: 'url(#clip-shape)',
                                                    position: 'absolute',
                                                }}
                                            >
                                                <img
                                                    src={BrainPower}
                                                    alt="Brain Power"
                                                    className="object-cover w-full h-full"
                                                    style={{
                                                        position: 'relative',
                                                        top: `${topValue}px`,
                                                    }}
                                                />
                                            </div>
                                        );
                                    })()}
                                    <img
                                        src={BrainIcon}
                                        alt="Brain Icon"
                                        className="object-cover w-full h-full"
                                        style={{
                                            position: 'relative',
                                            zIndex: 10,
                                        }}
                                    />
                                </div>

                                {/* Level System */}
                                <div className="relative flex flex-col items-center">
                                    <svg className="w-32 h-32 xl:w-44 xl:h-44 2xl:w-48 2xl:h-48" viewBox="0 0 100 100">
                                        <circle cx="50" cy="50" r="45" stroke="#333" strokeWidth="4" fill="transparent" />
                                        <circle
                                            cx="50"
                                            cy="50"
                                            r="0"
                                            stroke="#1DA1F2"
                                            strokeWidth="4"
                                            fill="transparent"
                                            strokeDasharray="220"
                                            strokeDashoffset="47"
                                            transform="rotate(-90 50 50)"
                                        />
                                    </svg>
                                    <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-4xl sm:text-5xl font-bold">1</span>
                                </div>
                            </div>

                            {/* Notifications and Challenges Section */}
                            <div className="hidden sm:flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                                {/* Notifications */}
                                <div className="flex-1 flex flex-col">
                                    <h3 className="text-xl font-bold text-white mb-2">Notifications</h3>
                                    <div className="bg-black rounded-lg p-4 flex-1 overflow-y-auto border border-gray-600 flex items-center justify-center">
                                        <p className="text-sm text-gray-300">Coming Soon</p>
                                    </div>
                                </div>

                                {/* Challenges */}
                                <div className="flex-1 flex flex-col">
                                    <h3 className="text-xl font-bold text-white mb-2">Challenges</h3>
                                    <div className="bg-black rounded-lg p-4 flex-1 overflow-y-auto border border-gray-600 flex items-center justify-center">
                                        <p className="text-sm text-gray-300">Coming Soon</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default Home;