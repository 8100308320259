import React from 'react';
import Logo from '../res/img/logo.svg';
import { useNavigate } from 'react-router-dom';

const LogoComponent = ({ isDarkMode }) => {

    const navigate = useNavigate();
    function redirectHome() {
      navigate("/home"); // Redirect to the /home route
    }

    return <img alt='logo' src={isDarkMode === "true" ? Logo : Logo} className="cursor-pointer mr-1 h-10 sm:mr-3 sm:h-14" onClick={redirectHome} />;
};

export default LogoComponent;
