import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { serverURL } from '../constants';
import { Card, Spinner } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import found from '../res/img/found.svg';
import { FaTrash, FaTimes } from 'react-icons/fa';  // Import icons for trash can and cancel

const UserCourses = ({ userId }) => {
    const [courses, setCourses] = useState([]);
    const [processing, setProcessing] = useState(true);
    const [selectionMode, setSelectionMode] = useState(false);
    const [selectedCourses, setSelectedCourses] = useState([]);

    useEffect(() => {
        const fetchUserCourses = async () => {
            const postURL = `${serverURL}/api/courses?userId=${userId}`;
            try {
                const response = await axios.get(postURL);
                setCourses(response.data);
                setProcessing(false);
            } catch (error) {
                console.error("Error fetching user courses:", error);
                setProcessing(false); // Stop processing even on error
                // Optionally, you can show a user notification here
            }
        };
    
        fetchUserCourses();
    }, [userId]);
    

    const navigate = useNavigate();

    function redirectGenerate() {
        navigate("/create");
    }

    const handleCourse = async (content, mainTopic, isPublic, selectedModel, type, user, courseId, completed, end) => {
        const jsonData = JSON.parse(content);
        sessionStorage.setItem('courseId', courseId);
        sessionStorage.setItem('first', completed);
        sessionStorage.setItem('jsonData', JSON.stringify(jsonData));
        let ending = '';
        if (completed) {
            ending = end;
        }
    
        try {
            // Update the recent course
            await updateRecentCourse(courseId, userId); // Call the function to update recent course
        } catch (error) {
            console.error("Failed to update recent course:", error);
            // You could also show a user notification or handle the error more gracefully
        }
    
        // Navigate to the course page with the necessary data
        navigate('/course', { state: { jsonData: jsonData, mainTopic: mainTopic.toUpperCase(), isPublic: isPublic, selectedModel: selectedModel, type: type.toLowerCase(), userId: user, creatorId: user, courseId: courseId, end: ending } });
    };    

    const updateRecentCourse = async (courseId, userId) => {
        const dataToSend = {
            courseId: courseId,
            userId: userId,
        };
    
        const postURL = serverURL + `/api/recent`; // Construct the API URL
        try {
            // Send a POST request to update the recent course
            await axios.post(postURL, dataToSend);
        } catch (error) {
            console.error("Error updating recent course:", error);
            // Optionally, you can add further error handling here, like showing a notification to the user
        }
    };    

    const handleCertificate = (mainTopic, end) => {
        const ending = new Date(end).toLocaleDateString();
        navigate('/certificate', { state: { courseTitle: mainTopic, end: ending } });
    }

    const toggleSelectionMode = () => {
        setSelectionMode(!selectionMode);
        setSelectedCourses([]); // Reset selection when toggling
    };

    const handleSelectCourse = (courseId) => {
        if (selectedCourses.includes(courseId)) {
            setSelectedCourses(selectedCourses.filter(id => id !== courseId));
        } else {
            setSelectedCourses([...selectedCourses, courseId]);
        }
    };

    const handleDeleteSelected = async () => {
        try {
            const postURL = serverURL + '/api/delete';
            // Loop over each selected course and send a delete request for each
            await Promise.all(
                selectedCourses.map(async (courseId) => {
                    await axios.post(postURL, { courseId });
                })
            );
    
            // Filter out the deleted courses locally
            const remainingCourses = courses.filter(course => !selectedCourses.includes(course._id));
            setCourses(remainingCourses);  // Update the state to show remaining courses
            setSelectedCourses([]);        // Clear the selection
            setSelectionMode(false);       // Exit selection mode
    
        } catch (error) {
            console.error("Error deleting courses", error);
        }
    };

    const style = {
        "root": {
            "base": "flex flex-col rounded-none border border-black bg-white shadow-none dark:border-white dark:bg-black mx-2 my-4",
            "children": "flex h-full flex-col justify-center gap-3 p-5",
            "horizontal": {
                "off": "flex-col",
                "on": "flex-col md:flex-row"
            },
            "href": "hover:bg-white dark:hover:bg-black"
        },
        "img": {
            "base": "",
            "horizontal": {
                "off": "rounded-none",
                "on": "h-96 w-full rounded-none object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
            }
        }
    };

    return (
        <div className='my-4'>
            {processing ? (
                <div className="text-center h-screen w-screen flex items-center justify-center">
                    <Spinner size="xl" className='fill-black dark:fill-white' />
                </div>
            ) : (
                <>
                        <div className='flex justify-between items-center space-x-2 mb-4'>
                            {/* "Cancel Selection" and "Trash Can" buttons on the left */}
                            <div className="flex items-center">
                                <button
                                    onClick={toggleSelectionMode}
                                    className='border-b-0 text-white  font-normal mb-2 mt-2 bg-black dark:text-black dark:bg-white  hover:bg-black dark:hover:bg-white md:dark:hover:bg-white md:hover:bg-black hover:text-white md:hover:text-white dark:hover:text-black dark:md:hover:text-black' style={{ paddingLeft: '15px', paddingRight: '15px', paddingBottom: '10px', paddingTop: '10px' }}>
                                    {selectionMode ? 'Cancel Selection' : 'Select Courses'}
                                </button>

                                {selectionMode && (
                                    <button onClick={handleDeleteSelected} className='bg-red-500 text-white p-2 rounded ml-4'>
                                        <FaTrash />
                                    </button>
                                )}
                            </div>
                        </div>


                    {courses.length === 0 ? (
                        <div className="text-center h-center flex flex-col items-center justify-center">
                            <img alt='img' src={found} className='max-w-sm h-3/6' />
                            <p className='text-black font-black dark:text-white text-xl'>Nothing Found</p>
                            <button onClick={redirectGenerate} className='bg-black text-white px-5 py-2 mt-4 font-medium dark:bg-white dark:text-black'>
                                Generate Course
                            </button>
                        </div>
                    ) : (
                        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
                            {courses.map((course) => (
                                <div key={course._id} className='w-full relative'>
                                    {/* Display a checkbox only in selection mode, now on the left */}
                                    {selectionMode && (
                                        <div
                                            className={`absolute top-6 left-4 w-6 h-6 border-2 border-black dark:border-white cursor-pointer flex items-center justify-center ${selectedCourses.includes(course._id) ? 'bg-blue-500' : 'bg-white dark:bg-black'}`}
                                            onClick={() => handleSelectCourse(course._id)}
                                        >
                                            {selectedCourses.includes(course._id) && (
                                                <span className="text-white dark:text-black font-bold">✓</span>
                                            )}
                                        </div>
                                    )}
                                    <Card imgSrc={course.photo} theme={style}>
                                        <h5 style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} className='text-xl font-black tracking-tight text-black dark:text-white'>
                                            {course.mainTopic.toUpperCase()}
                                        </h5>
                                        <p className='font-normal text-sm capitalize text-black dark:text-white'>
                                            {course.type}
                                        </p>
                                        <p className='font-normal text-sm text-black dark:text-white'>
                                            {new Date(course.date).toLocaleDateString()}
                                        </p>
                                        <div className='flex-row flex space-x-4'>
                                            <button onClick={() => handleCourse(course.content, course.mainTopic, course.public, course.selectedModel, course.type, course.user, course._id, course.completed, course.end)} className='bg-black text-white px-5 py-2 font-medium dark:bg-white dark:text-black'>
                                                Continue
                                            </button>
                                            {course.completed ? (
                                                <button onClick={() => handleCertificate(course.mainTopic, course.end)} className='border-black text-black border px-5 py-2 font-medium dark:border-white dark:text-white'>
                                                    Certificate
                                                </button>
                                            ) : null}
                                        </div>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default UserCourses;
