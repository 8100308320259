import React, { useState } from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import { FreeType, FreeCost, FreeTime, MonthType, MonthApi, MonthTime, MonthCost, YearType, YearApi, YearCost, YearTime } from '../constants';
import PricingPlan from '../components/pricing';
import { useLocation, useNavigate } from 'react-router-dom';

const Pricing = () => {

    const { state } = useLocation();
    const { header } = state || false;

    const freeData = `{"type": "${FreeType}", "cost": "${FreeCost}", "time": "${FreeTime}", "one": "5,000"}`;
    const parsedFreeData = JSON.parse(freeData);

    const monthData = `{"type": "${MonthType}", "api": "${MonthApi}", "cost": "${MonthCost}", "time": "/${MonthTime}", "one": "5,000,000 Tokens"}`;
    const parsedMonthData = JSON.parse(monthData);

    const YearData = `{"type": "${YearType}", "api": "${YearApi}", "cost": "${YearCost}", "time": "/${YearTime}", "one": "10,000,000 Tokens"}`;
    const parsedYearData = JSON.parse(YearData);

    const navigate = useNavigate();

    function redirectCancel() {
        navigate("/cancellation");
    }

    function redirectRefund() {
        navigate("/refund");
    }

    function redirectBilling() {
        navigate("/billing");
    }

    function redirectContact() {
        navigate("/contact");
    }

    const data = [
        { question: 'What is our cancellation policy ?', answer: <p>You can read our cancellation policy from <p className='text-blue-800 underline' onClick={redirectCancel}>here</p></p> },
        { question: 'What is our refund policy ?', answer: <p>You can read our refund policy from <p className='text-blue-800 underline' onClick={redirectRefund}>here</p></p> },
        { question: 'What is our subscription and billing policy ?', answer: <p>You can read our subscription and billing policy from <p className='text-blue-800 underline' onClick={redirectBilling}>here</p></p> },
        { question: 'What are the available payment gateways ?', answer: 'Our platform supports multiple payment gateways, including PayPal, and Razorpay.' },
    ];

    const [expandedItem, setExpandedItem] = useState(null);

    const handleExpand = (index) => {
        setExpandedItem(expandedItem === index ? null : index);
    };

    const style = {
        "root": {
            "base": "max-w-sm max-md:max-w-xs flex rounded-none border border-black bg-white shadow-none dark:border-white dark:bg-black m-4",
            "children": "flex h-full flex-col justify-center gap-3 p-5",
            "horizontal": {
                "off": "flex-col",
                "on": "flex-col md:max-w-xl md:flex-row"
            },
            "href": "hover:bg-white dark:hover:bg-black"
        },
        "img": {
            "base": "",
            "horizontal": {
                "off": "rounded-none",
                "on": "h-96 w-full rounded-none object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
            }

        }
    }

    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={header} className="sticky top-0 z-50" />
            <div className='dark:bg-black flex-1'>
                <div className='flex-1 flex flex-col items-center justify-center'>
                    <h1 className="text-6xl font-black mt-14 max-md:text-3xl dark:text-white">Pricing Plan</h1>
                    <p className="text-center text-black mt-6 max-w-2xl font-medium max-md:text-xs dark:text-white">
                        Choose the Perfect Plan for Your Success
                    </p>
                    <div className="flex justify-center py-14">
                        <div className="flex flex-col items-center justify-center px-3 max-md:pt-4">
                            <PricingPlan data={parsedMonthData} header={header} />
                        </div>

                        <div className="flex flex-col items-center justify-center px-3 max-md:pt-4">
                            <PricingPlan data={parsedYearData} header={header} />
                        </div>
                    </div>
                </div>
            </div>
            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default Pricing;
